import { FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_REQUEST ,FETCH_CONTACTS_FAILURE, UPDATE, ACCEPT_CONTACT, ADD_CONTACT, ACCEPT_CONTACT_SUCCESS, ADD_MESSAGE, ADD_MESSAGE_SUCCESS, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE, INCOMING_MESSAGE_SUCCESS,
        FETCH_CONTACTS_FILTERED_REQUEST,FETCH_CONTACTS_FILTERED_FAILURE,FETCH_CONTACTS_FILTERED_SUCCESS, EMPTY_CONTACTS, CHECK_UNREAD,
        INCOMING_MESSAGE_FAILURE, ALL_MESSAGE_READ_SUCCESS, CONTACT_DIPOSITION_SUCCESS,UPDATE_CONTACT_DATOS, UPDATE_SUCCESS,UPDATE_FAILURE, UPDATE_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE, FETCH_MESSAGES_REQUEST, SEND_MESSAGE_REQUEST,
        FETCH_MESSAGES_FILTERED_REQUEST, FETCH_MESSAGES_FILTERED_SUCCESS, FETCH_MESSAGES_FILTERED_FAILURE, SET_MESSAGE_READ, SET_MESSAGE_DELIVERED, SET_MESSAGE_REJECTED, REMOVE_CONTACT, FETCH_CLIENT_DATA_SUCCESS, SET_MESSAGE_REACTED, INSERT_MESSAGEREFERENCE,
        FETCH_UNREAD_MERCADO_LIBRE, REDUCE_UNREAD_MERCADO_LIBRE,FETCH_ADO_CLIENT_DATA_SUCCESS, SEND_MESSAGE_REACTION_SUCCESS
} from './constants';
import { produce } from 'immer';
import moment from 'moment';
import isEqual from 'lodash.isequal';

const INITIAL_STATE = {
  loading: { isLoadingMessages : false , isLoadingContacts : false },
  contacts: [],
  mercadoLibre : { unread : 0 },
  contactsFiltered : [],
  chats: [],
  live: [],
  activeContact : {},
  sinleer : { mensajesSinleer  : false }
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case FETCH_CONTACTS_REQUEST:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingMessages = true;
      });

    case FETCH_CONTACTS_SUCCESS:
      return produce(state, (draftState) => {

        const getContactMessages = (id) => {
           let value = state.contacts.find( (contact) => contact.id == id)
           return value ? value.messages : [];
        }

        let contacts =  action.payload.map( contact => ({
            ...contact , messages : getContactMessages(contact.id)
        })
        )
        draftState.contacts = contacts;
        draftState.loading.isLoadingMessages = false;
    });
      
    case FETCH_CONTACTS_FAILURE:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingMessages = false;
    });

    case FETCH_UNREAD_MERCADO_LIBRE:
      return produce(state, (draftState) => {
        let unreadCount = action.payload.reduce((accumulator, question) => accumulator + question.unread , 0)
        draftState.mercadoLibre.unread = unreadCount;
    });

    case REDUCE_UNREAD_MERCADO_LIBRE:
      return produce(state, (draftState) => {
        draftState.mercadoLibre.unread = draftState.mercadoLibre.unread - action.payload;
    });

          
    case FETCH_CONTACTS_FILTERED_REQUEST:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingMessages = true;
      });

    case FETCH_CONTACTS_FILTERED_REQUEST:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingMessages = true;
      });  

    case FETCH_CONTACTS_FILTERED_SUCCESS:
      return produce(state, (draftState) => {
        draftState.contactsFiltered = [...action.payload];
        draftState.loading.isLoadingMessages = false;
      });
      
    case FETCH_CONTACTS_FILTERED_FAILURE:
          return produce(state, (draftState) => {
            draftState.loading.isLoadingMessages = false;
          }); 

    case ACCEPT_CONTACT_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.id === contact.id
        ));
        draftState.contacts[index].status = 1;
      });

    case REMOVE_CONTACT:
      return produce(state, (draftState) => {

        try{
          const index = draftState.contacts.findIndex(contact => (
            action.payload.id === contact.id
          ));
          draftState.contacts[index].transfered = true;
        }catch(err){
          console.error(err ,'Al poner contact como transfered')
        }
        
      });

    case EMPTY_CONTACTS:
        return produce(state, (draftState) => {
          draftState.contacts = [];
        }); 
    
    case ADD_CONTACT:
      return produce(state, (draftState) => {
        console.log('pasa x add contact')
        console.log(action.payload)
        draftState.contacts.unshift(action.payload);
      }); 

    case UPDATE_CONTACT_DATOS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactid === contact.id
        ));
        console.log(action.payload, 'action.payload')
        draftState.contacts[index].name = action.payload.name;
        draftState.contacts[index].Nombres = action.payload.name;
        draftState.contacts[index].surname = action.payload.surname;
        draftState.contacts[index].Tipo_Docu  = action.payload.Tipo_Docu;
        draftState.contacts[index].Nro_Docu = action.payload.Nro_Docu;
        draftState.contacts[index].E_Mail = action.payload.E_Mail;
        draftState.contacts[index].utm_source = action.payload.utm_source;
        draftState.contacts[index].utm_medium = action.payload.utm_medium;
        draftState.contacts[index].utm_campaign = action.payload.utm_campaign;
        draftState.contacts[index].utm_source = action.payload.utm_source;
      });   

    case UPDATE_REQUEST:
      return produce(state, (draftState) => {
        draftState.loading.isLoading = true;
      });    
    
    case UPDATE_FAILURE:
        return produce(state, (draftState) => {
          draftState.loading.isLoading = true;
        });    

    case UPDATE_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.id === contact.id
        ));
        draftState.contacts[index].status = action.payload.status;
        draftState.loading.isLoading = false;
      });   
  
      case SET_MESSAGE_READ:
        return produce(state, (draftState) => {

          
          const index = draftState.contacts.findIndex(contact => (
            action.payload.contactid === contact.id
          ));
  
          try{
            const indexMessage = draftState.contacts[index].messages.findIndex(message => {
              return action.payload.mid == message.id
            })

            if(indexMessage){
              draftState.contacts[index].messages[indexMessage].read = true;
            }
          }catch(error){
            console.warn('Trato de actualizar contacto que no esta en reducer')
          }
            
          
          
        });
    

      case SET_MESSAGE_DELIVERED:
       return produce(state, (draftState) => {
        console.log(action.payload)

        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactid === contact.id
        ));

        const indexMessage = draftState.contacts[index].messages.findIndex(message => {
          return action.payload.mid == message.id
        })
        
        if(indexMessage){
          draftState.contacts[index].messages[indexMessage].delivered = true;     
        }
            
      });

      case SET_MESSAGE_REJECTED:
        return produce(state, (draftState) => {
          console.log(action.payload)
  
          const index = draftState.contacts.findIndex(contact => (
            action.payload.contactid === contact.id
          ));
  
          const indexMessage = draftState.contacts[index].messages.findIndex(message => {
            return action.payload.mid == message.id
          })
          
          if(indexMessage){
            draftState.contacts[index].messages[indexMessage].hasError = true;
            draftState.contacts[index].messages[indexMessage].errorMessage = action.payload.errorMessage;
          }
              
        });

    case ADD_MESSAGE:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].messages.push(action.payload.message)
      });

    case ADD_MESSAGE_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].messages = action.data;
      });
  
    case SEND_MESSAGE_REACTION_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));

        const indexMessage = draftState.contacts[index].messages.findIndex(message => {
          return action.payload.message.id === message.messageid 
        })
        
        draftState.contacts[index].messages[indexMessage].reacted = true;
        draftState.contacts[index].messages[indexMessage].attachmentName = action.payload.message.msg;
      });  

    case SEND_MESSAGE_REQUEST:
        return produce(state, (draftState) => {
          const index = draftState.contacts.findIndex(contact => (
            action.payload.contactId === contact.id
          ));

          draftState.contacts[index].messages.push(action.payload.message);
        });  

    case SEND_MESSAGE_SUCCESS:
      return produce(state, (draftState) => {
        const message = action.payload.message;
        const data = action.payload.data.data;

        const index = draftState.contacts.findIndex(
          contact => message.contactId === contact.id
        );

        const indexMessage = draftState.contacts[index].messages.findIndex(x => {
          return isEqual(x,message.message)
        })
        draftState.contacts[index].messages[indexMessage].sent = true;
        draftState.contacts[index].messages[indexMessage].id = data.id;
        draftState.contacts[index].messages[indexMessage].time = data.messagetime;

      });  

    case INCOMING_MESSAGE_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].messages.push(action.payload.message)
        draftState.contacts[index].unread = draftState.contacts[index].messages.filter(x => x.ReadByAgent == null && x.outbound === false).length;
        draftState.contacts[index].lastMsg = action.payload.message.msg;
        draftState.contacts[index].lastMsgTime = action.payload.lastInboundMsgTime;
        console.log('pasa x incomindg message succes')
        if((draftState.contacts[index].dueShift !== 0 || draftState.contacts[index].dueShift !== 9999 ) && draftState.contacts[index].dueShift !== undefined){
          draftState.contacts[index].messageDue = moment(action.payload.lastMsgInboundTime).add(draftState.contacts[index].dueShift, 'hours');
        }
        if( action.payload.lastMsgReference && action.payload.lastMsgReference !== ''){
          draftState.contacts[index].lastMsgReference = action.payload.lastMsgReference;
        }
        let removedElement = Object.assign(draftState.contacts[index],{});
        draftState.contacts.splice(index, 1);
        draftState.contacts.unshift(removedElement);
      });     
      
    case ALL_MESSAGE_READ_SUCCESS:
        return produce(state, (draftState) => {
          const index = draftState.contacts.findIndex(contact => (
            action.payload.contactId === contact.id
          ));
          
          for(var i =0 ; i < draftState.contacts[index].messages.length ; i++){
            if(draftState.contacts[index].messages[i].ReadByAgent == null){
              draftState.contacts[index].messages[i].ReadByAgent = action.payload.agentId
            }
          }
          //draftState.contacts[index].unread = draftState.contacts[index].messages.filter(x => x.ReadByAgent == null && x.outbound === false).length;
          draftState.contacts[index].unread = 0;
          
        });  
        
    case CONTACT_DIPOSITION_SUCCESS:
      return produce(state, (draftState) => {
        const filtered = draftState.contacts.filter(contact => (
          action.payload.id !== contact.id
        ));
        draftState.contacts = filtered;
      });
      
    case CHECK_UNREAD:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          contact.unread > 0
        ));
        draftState.sinleer.mensajesSinleer = index != -1 ? true : false;
      });   
    
    case FETCH_MESSAGES_FILTERED_FAILURE:
        return produce(state, (draftState) => {
          draftState.loading.isLoadingMessages = false;
        });   
        
    case FETCH_MESSAGES_FILTERED_REQUEST:
          return produce(state, (draftState) => {
            draftState.loading.isLoadingMessages = true;
          });      
    
  
    case FETCH_MESSAGES_FILTERED_SUCCESS:
        return produce(state, (draftState) => {
          const index = draftState.contactsFiltered.findIndex(contact => (
            action.payload.contactId === contact.id
          ));
          draftState.contactsFiltered[index].messages = action.payload.messages
          draftState.loading.isLoadingMessages = false;
  
        });     

    case FETCH_MESSAGES_FAILURE:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingMessages = false;
      });   
      
    case FETCH_MESSAGES_REQUEST:
        return produce(state, (draftState) => {
          draftState.loading.isLoadingMessages = true;
        });      
  
    case INSERT_MESSAGEREFERENCE:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].messages = action.payload.messages
        draftState.loading.isLoadingMessages = false;

      });       


    case FETCH_MESSAGES_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].messages = action.payload.messages
        draftState.loading.isLoadingMessages = false;

      });  
      
    case FETCH_ADO_CLIENT_DATA_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        let adoData = action.payload.data;

        draftState.contacts[index].messages.push({
          "from": "OMNI",
          "fecha": new Date(),
          "fechaCorta": "22/12/2024",
          "messagetype": "prompt",
          "inbound": false,
          "deleted": null,
          "originAttachmentURL": null,
          "msg": `Se encontraron estos datos en el CRM:  ${adoData.Nombres} ${adoData.Apellido} , DNI : ${adoData.nro_docu}, querés cargarlos en el contacto?`,
          "isSecret": true,
          "isPrompt" : true,
          "campaign": "9AE8D995-6789-EE11-86F8-005056B12F1E",
          "outbound": 1,
          "elapsed": 39,
          "button1": "https://www.tevecompras.com/checkout/cart/add?sku=864&qty=1&seller=1&sc=1&utm_source=whatsapp&utm_campaign=CARRITO",
          "button1Name": "Cargar Datos",
          "button1Type": "URL",
          "adoData": adoData
        });

      });  
      
    case FETCH_CLIENT_DATA_SUCCESS:
      return produce(state, (draftState) => {

        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactId === contact.id
        ));
        draftState.contacts[index].clientData = action.payload.data;

      }); 
      
    case SET_MESSAGE_REACTED:
      return produce(state, (draftState) => {

        
        const index = draftState.contacts.findIndex(contact => (
          action.payload.contactid === contact.id
        ));

        try{
          const indexMessage = draftState.contacts[index].messages.findIndex(message => {
            return action.payload.mid == message.id
          })

          if(indexMessage){
            draftState.contacts[index].messages[indexMessage].attachmentName = action.payload.attachmentName;
            draftState.contacts[index].messages[indexMessage].reacted = true;
          }
        }catch(error){
          console.warn('SET_MESSAGE_REACTED - Trato de actualizar contacto que no esta en reducer')
        }
        
      });

    default: return state;
  }
  
}
