import React, {useState, useEffect, useCallback} from 'react';

import {InputAdornment, Button , Input, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const ModalMostrarUtms = ( props ) => {
    const [commentText,setCommentText] = useState('');
    const [utm_campaign, setUtmCampaign] = React.useState(props.dataPanel.utm_campaign ? props.dataPanel.utm_campaign : '');
    const [utm_medium, setUtmMedium] = React.useState(props.dataPanel.utm_medium ? props.dataPanel.utm_medium : '');
    const [utm_source, setUtmSource] = React.useState(props.dataPanel.utm_source ? props.dataPanel.utm_source : '');
    const [link, setLink] = React.useState();
    const [linkParams, setLinkParams] = React.useState({});
    const [linkCheck, setLinkCheck] = React.useState();
    const [preview,setPreview] = React.useState()


    const getUtmCampaign = () => {
        let tempName;
        let username = props.username;
        if(username.includes("_")){
            tempName = username.split("_");
        }else{
            tempName = username;
        }

        let nameCode =  tempName[0].substring(0, 1) + tempName[1].substring(0, 1);

        return nameCode + props.contact.campaignid;
    }
    

    const handleClose = () => {
        setCommentText('')
        props.onClose()
        setLink('')
        setLinkParams({})
        setLinkCheck(false)
        setPreview('')
    }

    const sendMessage = () => {
        props.onSend(commentText)
        setCommentText('')
    }

    const getDefaultUtmMedium = () => {
        if (props.contact.source == 'whatsapp' || props.contact.source == 'whatsapp-sandbox'){
            return 'Whatsapp';
        }
        if (props.contact.source == 'chat'){
            return 'Chat';
        }
        if (props.contact.source == 'facebook'){
            return 'Facebook';
        }

        return '';

    }

    const validCampos = () => {
        if(utm_campaign && utm_source && linkCheck){
            return false;
        }else{
            return true;
        }
    }
 
    const getUtmCampaignInit = () => {
        if (linkParams.utm_campaign){
            return linkParams.utm_campaign
        }

        if (props.dataPanel.utm_campaign){
            return props.dataPanel.utm_campaign
        }
        return getUtmCampaign();
    }

    const getUtmMediumInit = () => {
        if (linkParams.utm_medium) {
            return linkParams.utm_medium
        }

        if (props.dataPanel.utm_medium) {
            return props.dataPanel.utm_medium
        }

        if (props.contact.campaignid == 36) {
            return 'meta-wa-ads'
        }
  
        return getDefaultUtmMedium();
    }

    const getUtmSourceInit = () => {
        if (linkParams.utm_source) {
            return linkParams.utm_source
        }

        if (props.dataPanel.utm_source) {
            return props.dataPanel.utm_source
        }

        if (props.contact.campaignid == 36){
            return 'omni-mkt'
        }

        return 'omnichannel';
    }

    const replaceSearchParams = (urlParam, utms) => {
            var url = new URL(urlParam);
            Object.keys(utms).forEach((key) => {
                if (utms && utms[key].length > 0){
                    url.searchParams.set(key, utms[key])
                }else{
                    url.searchParams.delete(key)
                }
                
            });

            return url.toString();
    }

    useEffect(
        () => {

            setUtmCampaign(getUtmCampaignInit())
            setUtmMedium(getUtmMediumInit())
            setUtmSource(getUtmSourceInit())
    
        }, 
    [props,linkParams]);


    const getSearchParams = (urlParam) => {
        try {
            var url = new URL(urlParam);
            let params = new URLSearchParams(url.search)

            let returnParams = {
                utm_source: params.get('utm_source'),
                utm_medium: params.get('utm_medium'),
                utm_campaign: params.get('utm_campaign')
            };

            setLinkParams(returnParams)
        }
        catch (error) {
            console.error(error)
            return '';
        }

    }


    useEffect(
        () => {
            try {
                new URL(link);
                setLinkCheck(true)
                getSearchParams(link)
                setPreview(replaceSearchParams(link, { utm_campaign: utm_campaign, utm_medium: utm_medium, utm_source: utm_source }))
            } catch (_) {
                setLinkCheck(false)
            }
        },
    [link]);

    useEffect(
        () => {
            if (linkCheck) {
                setPreview(replaceSearchParams(link, { utm_campaign: utm_campaign, utm_medium: utm_medium, utm_source: utm_source }))
            }
        },
    [utm_campaign, utm_medium, utm_source]);


    const enviar = () => {
        props.onSendUtms( 'utmsReplace' , preview );
        handleClose()
        props.onClose();
    }

    return (
        <>
            <Dialog maxWidth={'xl'} style={{zIndex: 1500}} open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Link Tevecompras</DialogTitle>
                <DialogContent  style={{width: '500px'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Nota"
                        label="Link"
                        value={link}
                        onChange={({ target: { value } }) => setLink(value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="start"> {linkCheck ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}  </InputAdornment>,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Nota"
                        label="utm_source"
                        value={utm_source}
                        onChange={({ target: { value } }) => setUtmSource(value)}
                        
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Nota"
                        label="utm_medium"
                        value={utm_medium}
                        onChange={({ target: { value } }) => setUtmMedium(value)}
                       
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Nota"
                        label="utm_campaign"
                        value={utm_campaign}
                        onChange={({ target: { value } }) => setUtmCampaign(value)}
                        
                        fullWidth
                    />
                    <br/><br/>
                    Preview <br/>  
                    <div style={{lineBreak: 'anywhere'}}>
                        {preview}
                    </div>
                    
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Cancelar
                    </Button>
                    <Button onClick={() => enviar() } color="primary" disabled={validCampos()}>
                    Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalMostrarUtms;