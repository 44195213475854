export const FETCH = 'getAll';
export const FETCH_CONTACTS_REQUEST = 'contacts/FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_FAILURE = 'contacts/FETCH_CONTACTS_FAILURE';
export const FETCH_CONTACTS_SUCCESS = 'contacts/FETCH_CONTACTS_SUCCESS';

export const EMPTY_CONTACTS = 'contacts/EMPTY_CONTACTS';

export const FETCH_CONTACTS_FILTERED_REQUEST = 'contacts/FETCH_CONTACTS_FILTERED_REQUEST';
export const FETCH_CONTACTS_FILTERED_FAILURE = 'contacts/FETCH_CONTACTS_FILTERED_FAILURE';
export const FETCH_CONTACTS_FILTERED_SUCCESS = 'contacts/FETCH_CONTACTS_FILTERED_SUCCESS';


export const UPDATE_CONTACT_DATOS = 'contacts/UPDATE_CONTACT_DATOS';

export const UPDATE_REQUEST = 'contacts/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'contacts/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'contacts/UPDATE_FAILURE';

export const ADD_CONTACT = 'contacts/ADD_CONTACT';
export const ACCEPT_CONTACT = 'contacts/ACCEPT_CONTACT';
export const ACCEPT_CONTACT_SUCCESS = 'contacts/ACCEPT_CONTACT_SUCCESS';
export const ACCEPT_CONTACT_FAILURE = 'contacts/ACCEPT_CONTACT_FAILURE';

export const REMOVE_CONTACT = 'contacts/REMOVED_CONTACT';

export const ADD_MESSAGE = 'messages/ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'messages/ADD_MESSAGE_SUCCESS';

export const SEND_MESSAGE_REQUEST = 'messages/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'messages/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'messages/SEND_MESSAGE_FAILURE';

export const ALL_MESSAGE_READ_SUCCESS = 'messages/ALL_MESSAGE_READ_SUCCESS';
export const ALL_MESSAGE_READ_FAILURE = 'messages/ALL_MESSAGE_READ_FAILURE';

export const INCOMING_MESSAGE_SUCCESS = 'message/INCOMING_MESSAGE_SUCCESS';
export const INCOMING_MESSAGE_FAILURE = 'message/INCOMING_MESSAGE_FAILURE';
export const INCOMING_MESSAGE_ACKNOWLEDGE = 'message/INCOMING_MESSAGE_ACKNOWLEDGE';
export const INCOMING_MESSAGE_READ = 'message/INCOMING_MESSAGE_READ';

export const CONTACT_DIPOSITION_SUCCESS = 'contacts/CONTACT_DIPOSITION_SUCCESS';
export const CONTACT_DIPOSITION_FAILURE = 'contacts/CONTACT_DIPOSITION_FAILURE';

export const CHECK_UNREAD = 'messages/CHECK_UNREAD';
export const FETCH_MESSAGES_REQUEST = 'messages/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'messages/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'messages/FETCH_MESSAGES_FAILURE';

export const FETCH_MESSAGES_FILTERED_REQUEST = 'messages/FETCH_MESSAGES_FILTERED_REQUEST';
export const FETCH_MESSAGES_FILTERED_SUCCESS = 'messages/FETCH_MESSAGES_FILTERED_SUCCESS';
export const FETCH_MESSAGES_FILTERED_FAILURE = 'messages/FETCH_MESSAGES_FILTERED_FAILURE';

export const SET_MESSAGE_DELIVERED = 'messages/SET_MESSAGE_DELIVERED';
export const SET_MESSAGE_READ = 'messages/SET_MESSAGE_READ';
export const SET_MESSAGE_REJECTED = 'messages/SET_MESSAGE_REJECTED';
export const SET_MESSAGE_REACTED = 'messages/SET_MESSAGE_REACTED';

export const FETCH_CLIENT_DATA_REQUEST = 'contacts/FETCH_CLIENT_DATA_REQUEST';
export const FETCH_CLIENT_DATA_SUCCESS = 'contacts/FETCH_CLIENT_DATA_SUCCESS';
export const FETCH_CLIENT_DATA_FAILURE = 'contacts/FETCH_CLIENT_DATA_FAILURE';

export const FETCH_ADO_CLIENT_DATA_REQUEST = 'contacts/FETCH_ADO_CLIENT_DATA_REQUEST';
export const FETCH_ADO_CLIENT_DATA_SUCCESS = 'contacts/FETCH_ADO_CLIENT_DATA_SUCCESS';
export const FETCH_ADO_CLIENT_DATA_FAILURE = 'contacts/FETCH_ADO_CLIENT_DATA_FAILURE';

export const SEND_MESSAGE_REACTION_REQUEST = 'contacts/SEND_MESSAGE_REACTION_REQUEST';
export const SEND_MESSAGE_REACTION_SUCCESS = 'contacts/SEND_MESSAGE_REACTION_SUCCESS';
export const SEND_MESSAGE_REACTION_FAILURE = 'contacts/SEND_MESSAGE_REACTION_FAILURE';

export const INSERT_MESSAGEREFERENCE = 'contacts/INSERT_MESSAGEREFERENCE';

export const FETCH_UNREAD_MERCADO_LIBRE = 'contacts/FETCH_UNREAD_MERCADO_LIBRE';

export const REDUCE_UNREAD_MERCADO_LIBRE = 'contacts/REDUCE_UNREAD_MERCADO_LIBRE';








