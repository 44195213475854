import React, { useState, useEffect, useRef } from "react";
// import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import { green, indigo, pink, red } from '@material-ui/core/colors';

import clsx from 'clsx';


import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";

// import Layout from '../Layout';
import ContactList from './ContactList';
import Conversation from './Conversation';
import StatsData from './StatsData/StatsData';

import MenuIcon from "@material-ui/icons/Menu";

import * as appSelector from '../../state/app/selectors';
import * as authSelectors from '../../state/auth/selectors';
import * as contactsActions from '../../state/contacts/actions';


import { Grid, Drawer, Toolbar, IconButton } from "@material-ui/core";
import ContactListBusquedaSuper from "./ContactList/ContactListBusquedaSuper";
import MenuRightChats from './../MenuRight/MenuRightChats';

const drawerWidth = 500;

const useStyles = makeStyles((theme, largeScreen) => ({
    root: {
      display: 'flex',
    },
    containerStyles : {
      marginRight : '57px',
       transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
       }),
    },
    containerStylesShift: {
      marginRight:  '300px' 
    },
    containerStylesShiftBig: {
      marginRight: '500px'
    },
    leftContainer: {
      position: 'relative',
      width: '300px',
      overflow: "auto"
    },
    leftContainerBig: {
      position: 'relative',
      width: '350px',
      overflow: "auto"
    },
    listItemPrimaryText:{
      color: '#000',
      fontSize:'1.6em',
      paddingLeft: 10,
      fontWeight: 'bold'
    },
    listItemSecondaryText:{
      color: '#333',
      fontSize:'1.1em',
      paddingLeft: 10
    },
    sendIcon: {
      fontSize: 24
    },
    EmptyF: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
    },
    rightContainer: {
      flex : 1,
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 65px)",
      border: theme.palette.borders
    },
    rightfloatedDiv: {
      position : 'fixed',
      width : '50px',
      width: '50px',
      height: '50px',
      position: 'fixed',
      right: '82px',
      top: '94px'
    },
    sendButton: {
      padding: 15,
      margin: 10
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      // justifyContent: 'flex-start',
    },
  }
))

const ChatsDashboard = (props) => {
  const resizeSize = 1500;
  const [isLargeScreen, setLargeScreen] = useState(window.innerWidth > resizeSize);
  console.log(isLargeScreen)
  const classes = useStyles(useTheme(), isLargeScreen);
  const dispatch = useDispatch();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const menu = useSelector(appSelector.getChatsMenuState());
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const contactListRef = useRef();
  const contactListSuperRef = useRef();


  const updateMedia = () => {
    setLargeScreen(window.innerWidth > resizeSize);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  },[]);

  useEffect(() => {
    removeActiveContact();
    dispatch(contactsActions.emptyContacts());
    if (!props.todas && !props.super && !props.verificados && !props.mercadolibre ){
      dispatch(contactsActions.fetchContacts(me.username));
      console.log('pasa x if 1')
    }
    if (props.verificados){
      dispatch(contactsActions.fetchContactsVerificados(me.username));
      console.log('pasa x if 2')
    }
    if (props.mercadolibre) {
      dispatch(contactsActions.fetchContactsMercadoLibre(me.username));
      console.log('pasa x if 2')
    }

    return () => {
      removeActiveContact();
    }
  }, [props.todas, props.super, props.verificados]);



  const handleChangeDrawerState = () => {
    console.log('pasa x cambio drawer state')
    if(activeContact !== 0){
      setOpenDrawer(!openDrawer);
    }
  }

  const handleGuardoDatos = () => {
    if(props.super){
      contactListSuperRef.current.refreshData();
    }else{
      contactListRef.current.refreshData();
    }
    
  }

  const sendActiveContact = (event,contact) => {
    if(event){
      event.preventDefault();
    }

    changeActiveContact(contact);
  }
  
  const removeActiveContact = (contact) => {

    changeActiveContact(0);
  }
 
  const [activeContact, changeActiveContact] = useState(0)
  // const [activeContact, removeeActiveContact] = useState('')

  const [leftColumnOpen, setLeftColumnOpen] = useState('');
  const openLeftColumn = () => setLeftColumnOpen(true);
  const closeLeftColumn = () => setLeftColumnOpen(false);

  return (
    <div style={{ position : 'relative' }}>  
        <Grid container className={classes.root}>
        <Grid container className={clsx(classes.containerStyles, {
          [classes.containerStylesShift]: menu.isOpen && !isLargeScreen,
          [classes.containerStylesShiftBig]: menu.isOpen && isLargeScreen,
        })} >
          <Grid item className={clsx({ [classes.leftContainer]: !isLargeScreen, [classes.leftContainerBig]: isLargeScreen })}  style={{ overflow: 'hidden', height: 'calc(100vh - 65px)' }}>
                  { props.super ? <ContactListBusquedaSuper ref={contactListSuperRef} me={me} inbox={props.inbox} setActiveContact={sendActiveContact} removeActiveContact={removeActiveContact} ></ContactListBusquedaSuper> : <ContactList mercadolibre={props.mercadolibre} verificados={props.verificados} ref={contactListRef} todas={props.todas} me={me} setActiveContact={sendActiveContact}  removeActiveContact={removeActiveContact} /> }
              </Grid>
              <Grid item className={classes.rightContainer}   >
              {activeContact ? <Conversation handleGuardoDatos={handleGuardoDatos} super={props.super} removeActiveContact={removeActiveContact} todas={props.todas} contact={activeContact} key={activeContact.id} open={openDrawer} handleProfile={handleChangeDrawerState}/> : null }
              </Grid>
              {/* <Grid item zeroMinWidth className={classes.heightAdjust} xs={statsWidth} lg={4}  > */}
          </Grid>
        </Grid>
      <MenuRightChats isLargeScreen={isLargeScreen} handleGuardoDatos={handleGuardoDatos} activeContact={activeContact} />
    </div>
  );
  
}

export default ChatsDashboard;