import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'; 
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent, Button, Box, Typography, Fab, FormControl, Input, InputLabel, Select, MenuItem } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import MuiAlert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from "@material-ui/icons/Star";
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import FaceIcon from '@material-ui/icons/Face';
import Api from '../../../state/Api';
import BlockUi from 'react-block-ui';

import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import * as userActions from '../../../state/users/actions';
import * as appActions from '../../../state/app/actions';
import * as contactActions from '../../../state/contacts/actions';
import * as userSelectors from '../../../state/users/selectors';
import * as authSelectors from '../../../state/auth/selectors';


const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ActionsButton = ( props ) => {

  const classes = props.classes;

  const dispatch=useDispatch();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const users =  useSelector(userSelectors.getAllUsers())
  var AgeOptions = ['1','2','3'];
  const [direction, setDirection] = useState('right');
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [satisfactionRating, setSatisfaction] = useState(0);
  const [interestRating, setInterest] = useState(0);
  const [dipositionCode, setDipositionCode] = useState(0);
  const isDisabled = !!dipositionCode;
  const [moodCode, setMoodCode] = useState(0);
  const [selectedOperador, setOperador] = useState('');
  const [selectedGrupo, setGrupo] = useState('');
  const [comentarios,setComentarios] = useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [resultados,setResultados] =  useState([]); 
  const [enviandoDatos,setEnviandoDatos] =  useState(false); 
  const [grupos, setGrupos ] = useState([])
  const [operadores, setOperadores ] = useState([])

  const handleDirectionChange = event => {
    setDirection(event.target.value);
  };

  const validateResultado =  () => {
    if (!!dipositionCode && (dipositionCode != 8 && dipositionCode != 7) ){
      return false
    }

    if (!!dipositionCode && (dipositionCode == 8 || dipositionCode == 7 ) && !!selectedOperador){
      return false;
    }


    return true

  }

  const handleUpdateContact = event => {
    setEnviandoDatos(true)
    let selectedOperadorUpdate = (dipositionCode == 8 || dipositionCode == 7) ? selectedOperador : null;
    let update = {
      status : dipositionCode,
      agentRating : satisfactionRating,
      mood : moodCode,
      willBuy : interestRating,
      transferAgent : selectedOperadorUpdate,
      message : comentarios
    }
    dispatch(contactActions.updateContact(props.contact,me.username,update, () => { 
      setEnviandoDatos(false);
      handleModalClose() ;
      props.removeActiveContact();
      dispatch(appActions.addToast('Ha finalizado la interacción'))
      dispatch(contactActions.removeContact(props.contact))
    },() => {
      setEnviandoDatos(false);
      dispatch(appActions.addToast('Ha habido un error al actualizar el contacto','error'))
    }))
  }

  const handleHiddenChange = event => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };  

  const handleModalOpen = () => {
    Api.req.get(`/prospectos/campaigns/${props.contact.campaignid}/resultcodes`)
    .then((response) => {
       let unfiltered = response.data.data;
       let filteredRes = unfiltered.filter(result => result.resultado );
       setResultados(filteredRes)
    });
    dispatch(userActions.fetchAllUsers(props.contact.campaignid));
    setModalOpen(true);
  };


  
  const handleModalClose = () => {
    setModalOpen(false);
    setOperador('')
    setGrupo('')
    setSatisfaction(0)
    setDipositionCode(0)
    setMoodCode(0)
    setInterest(0)
  };  

  function getLabelText(value) {
    return `${value} Heart${value !== 1 ? 's' : ''}`;
  }

  const handleMood = (e, value) => {
    setMoodCode(value);
  }
  
  useEffect(() => {
    setGrupo('')
    setOperador('')
    if (dipositionCode == 7){
      Api.req.get(`/agents/squads`)
        .then((response) => {
          setGrupos(response.data.states)
        });
    }
  },[dipositionCode])

  useEffect(() => {

    if (selectedGrupo != ''){
      Api.req.get(`/agents/squads/${selectedGrupo}/agents`)
        .then((response) => {
          setOperadores(response.data.states)
        });
    } 
      
    
  }, [selectedGrupo])

  const getCustomForm = (code) => {
    //Transferencia
    if(code == 8){

      return (
        <FormControl style={{marginLeft: '10px'}}>
          <InputLabel id="demo-customized-select-label">Operador</InputLabel>
          <Select
            labelId="diposition-label"
            id="diposition"
            style={{ width: '200px' }}
            value={selectedOperador}
            onChange={({ target: { value } }) => setOperador(value)}
          >
            {
              users.items.length > 0 ? users.items.map((option, index) =>
                <MenuItem key={index} value={option.Agent}>{option.Agent}</MenuItem>
              ) : null}
          </Select>
        </FormControl>) 
    }
    //Transferencia Grupo
    if (code == 7) {

      return (
        <>
          <FormControl style={{ marginLeft: '10px' }}>
            <InputLabel id="demo-customized-select-label">Grupo</InputLabel>
            <Select
              labelId="diposition-label"
              id="diposition"
              style={{ width: '200px' }}
              value={selectedGrupo}
              onChange={({ target: { value } }) => setGrupo(value)}
            >
              {
                grupos.length > 0 ? grupos.map((option, index) =>
                  <MenuItem key={index} value={option.groupid}>{option.name}</MenuItem>
                ) : null}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: '10px' }}>
            <InputLabel id="demo-customized-select-label">Operador</InputLabel>
            <Select
              labelId="diposition-label"
              id="diposition"
              style={{ width: '200px' }}
              value={selectedOperador}
              onChange={({ target: { value } }) => setOperador(value)}
            >
              {
                operadores.length > 0 ? operadores.map((option, index) =>
                  <MenuItem key={index} value={option.Agent}>{option.Agent}</MenuItem>
                ) : null}
            </Select>
          </FormControl>
        </>
        )
    }
    
  }

  return (
    <div style={{position: 'relative', margin: '0 auto' }}>
      <Fab variant="extended" size="medium" color="secondary" style={{ marginTop:8 }} onClick={handleModalOpen}>
        <StarIcon style={{marginRight: 8}}/>
        FINALIZAR
      </Fab>
      <Dialog open={modalOpen} onClose={handleModalClose} aria-labelledby="form-dialog-title" maxWidth={maxWidth} disableBackdropClick>
        <BlockUi tag="div" blocking={enviandoDatos}>
        <DialogTitle id="form-dialog-title" variant="h2">FINALIZAR INTERACCION</DialogTitle>
        <DialogContent dividers style={{width: '700px'}}>
   
          {/* <DialogContentText>
            Seleccione una opción de Cierre de esta Interacción
          </DialogContentText> */}
            
            <Box component="fieldset" mb={6} borderColor="transparent">
              <FormControl>
                <InputLabel id="demo-customized-select-label">Resultado</InputLabel>
                <Select
                  labelId="diposition-label"
                  id="diposition"
                  value={dipositionCode}
                  onChange={({ target: { value } }) => setDipositionCode(value)}
                  style={{width:'200px'}}
                >
                {
                resultados.length > 0 ? resultados.map((option, index) => 
                   <MenuItem key={index} value={option.codigo}>{option.descripcion}</MenuItem>
                ) : null }
                <MenuItem value={0}>Seleccione</MenuItem>    
                </Select>
              </FormControl>

              { getCustomForm(dipositionCode) }
             
            </Box>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box component="fieldset"  borderColor="transparent">
                  <Typography component="legend">Calififación del agente</Typography>
                  <Rating
                    name="customized-empty"
                    value={satisfactionRating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    onChange={({ target: { value } }) => {setSatisfaction(Number(value))}}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box component="fieldset"  borderColor="transparent">
                  <Typography component="legend">Humor del cliente</Typography>
                      <ToggleButtonGroup size="medium" value={moodCode} exclusive onChange={handleMood}>
                        <ToggleButton key={1} value={-1}><MoodBadIcon /></ToggleButton>
                        <ToggleButton key={2} value={0}><FaceIcon /></ToggleButton>
                        <ToggleButton key={3} value={1}><MoodIcon /></ToggleButton>
                      </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item md={4}>
                  <Box component="fieldset"  borderColor="transparent">
                    <Typography component="legend">Interés de Compra</Typography>
                    <StyledRating
                      name="customized-color"
                      value={interestRating}
                      getLabelText={getLabelText}
                      precision={0.5}
                      icon={<FavoriteIcon fontSize="inherit" />}
                      onChange={({ target: { value } }) => setInterest(Number(value))}
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-multiline-static"
                  label="Comentarios"
                  multiline
                  fullWidth
                  rows={4}
                  variant="outlined"
                  value={comentarios}
                  onChange={({ target: { value } }) => setComentarios(value)}    
                />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={validateResultado()} onClick={handleUpdateContact} color="primary" >
            Finalizar
          </Button>
        </DialogActions>
        </BlockUi>
      </Dialog>       
    </div>
  )
}

export default ActionsButton ;
